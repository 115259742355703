import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Container, FlexBox } from '../components'

import { DefaultLayout } from '../layouts/defaultlayout'

import { Navigation } from './_navigation'


const flatten = (text, child) => {
  return typeof child === 'string' ? text + child : React.Children.toArray(child.props.children).reduce(flatten, text)
}

const renderers = {
  heading: (props) => {
    const children = React.Children.toArray(props.children)
    const text = children.reduce(flatten, '')
    const slug = text.toLowerCase().replace(/\W/g, '-')
    return <FlexBox>{React.createElement(`h${props.level}`, { id: slug }, props.children)}</FlexBox>
  },
}

interface MarkDownProps {
  content: string
  logo: any
}

const MarkDown = ({ content, logo }: MarkDownProps) => (
  <DefaultLayout>
    <Navigation
      logo={logo}
    />
    <Container>
      <ReactMarkdown renderers={renderers} source={content} />
    </Container>
  </DefaultLayout>
)

export { MarkDown }
